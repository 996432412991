import {BASE_API, BASE_API_GYM} from "../../utils/constants";
import { keywords } from "../../utils/keywords";
import Client from "../Client";
import ClientToken from "../ClientToken";
import {setToken} from "../token";

export async function loginApi(data) {
  try {
    const url = `${BASE_API_GYM}/session/auth/login`;
    const response = await Client.post(url, data);
    const status = await response.status
    if (status !== 200) {
      throw new Error(keywords.loginErrorMessage);
    }
    const result = await response.json();
    const { access } = result;
    await setToken(access);
    return await result;
  } catch (error) {
    throw error;
  }
}

export async function getMeApi() {
  try {
    const url = `${BASE_API_GYM}/session/auth/me`;
    const response = await ClientToken.get(url);
    const result = response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
