import { useState } from "react";
import { membershipApi } from "../api/membershipApi";
import {getKeyDateTypeEnum} from "../utils/membershipEnum";

export function useMembership() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [memberships, setMembership] = useState(null);

  const getMembership = async () => {
    try {
      setLoading(true);
      const response = await membershipApi.getMemberships();
      setLoading(false);
      setMembership(response.filter((membership) => membership.is_deleted === false));
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addMembership = async (data) => {
    try {
      setLoading(true);
      data.time = getKeyDateTypeEnum(data.time);
      await membershipApi.addMembership(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateMembership = async (id, data) => {
    try {
      setLoading(true);
      data.time = getKeyDateTypeEnum(data.time);
      await membershipApi.updateMembership(id, data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteMembership = async (id) => {
    try {
      setLoading(true);
      await membershipApi.deleteMembership(id);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    memberships,
    getMembership,
    deleteMembership,
    updateMembership,
    addMembership,
  };
}
