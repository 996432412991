const DateTypeEnum = {
  DAY: "Día",
  WEEK: "Semana",
  HALF_MONTH: "15 días",
  MONTH: "Mes",
  YEAR: "Año",
  NONE: "Sin Tiempo",
};

export function isDateTypeEnumMONTH(type) {
  switch (type) {
    case "MONTH":
      return true
      break;
    default:
      return false
  }
  return false;
}

export function addRelativeDate(date, type, oldAmount) {
  const amount = textToInt(oldAmount,0)
  const modifiedDate = new Date(date);
  switch (type) {
    case "DAY":
      modifiedDate.setDate(modifiedDate.getDate()+ amount);
      break;
    case "WEEK":
      modifiedDate.setDate(modifiedDate.getDate() + amount * 7);
      break;
    case "HALF_MONTH":
      modifiedDate.setDate(modifiedDate.getDate() + amount * 15);
      break;
    case "MONTH":
      modifiedDate.setMonth(modifiedDate.getMonth() + amount);
      break;
    case "YEAR":
      modifiedDate.setFullYear(modifiedDate.getFullYear() + amount);
      break;
    case "NONE":
      return null;
    default:
      throw new Error("Invalid date type.");
  }
  return modifiedDate;
}

export const DateTypeArray = Object.values(DateTypeEnum);

export function getMembershipTimeEnumValue(key) {
  return DateTypeEnum[key] || "";
}

function getKeyByValue(enumObj, value) {
  for (const key in DateTypeEnum) {
    if (DateTypeEnum[key] === value) {
      return key;
    }
  }
  return null;
}
function textToInt(text, defaultValue) {
  const result = parseInt(text, 10);
  return isNaN(result) ? defaultValue : result;
}
export const getKeyDateTypeEnum = (data) => getKeyByValue(DateTypeEnum, data);
