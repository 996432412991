import React, {useState} from "react";
import { Icon, Menu } from "semantic-ui-react";
import { useAuth } from "../../hooks";
import { keywords } from "../../utils/keywords";
import "./TopMenu.scss";
import {ExcelModal} from "../../pages/Admin/excel/ExcelModal";

export function TopMenu() {
  const { auth, logout } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const renderName = () => {
    if (auth?.me?.first_name && auth?.me?.last_name) {
      return `${auth.me.first_name} ${auth.me.last_name}`;
    }
    return auth?.me?.email;
  };

  const handleLogout = () => {
    if (window.confirm("CONFIMACIÓN DE CIERRE")) {
      logout();
    }
  };

  return (
    <Menu fixed="top" className="top-menu-admin">
      <Menu.Item className="top-menu-admin__logo">
        <p>{auth?.me?.email || keywords.companyName}</p>
      </Menu.Item>



      <Menu.Menu position="right">

        {auth && (process.env.NODE_ENV === 'development') && (
            <Menu.Item onClick={() => console.log('DEVE')}>
              DEVELOPMENT
            </Menu.Item>
        )}


        {auth && (
            <Menu.Item onClick={() => setIsModalVisible(true)}>
              {keywords.generate_excel}
            </Menu.Item>
        )}



        <ExcelModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />


        {auth && (
          <Menu.Item>
            {keywords.hello} {renderName()}
          </Menu.Item>
        )}
        <Menu.Item onClick={handleLogout}>
          <Icon name="sign-out" />
        </Menu.Item>
      </Menu.Menu>


    </Menu>
  );
}
