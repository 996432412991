export default {
  get(url) {
    return fetch(url);
  },

  async post(url, data) {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    return await fetch(url, params);
  },
};
