export const keywords = {
  hello: "hola,",
  generate_excel: "Generar excel",
  email: "Correo electronico",
  password: "contraseña",
  login: "Iniciar sesión",
  emailError: "Ingrese un correo valido",
  passwordError: "ingrese una contraseña",
  loginErrorMessage: "Usuario o contraseña incorrectos",
  companyName: "Nombre de la empresa",
  paymentsHistory: "Historial de pagos",
  products: "Productos",
  users: "Usuarios",
  image: "Imagen",
  membership: "Membresías",
  addMembership: "Agregar membresías",
  subscriber: "Miembros",
  new: "Nuevo",
  update: "Actualizar",
  delete: "Eliminar",
  deleteMesage: "Confirme la eliminación de",
  addMembershipSubscriber: "Agregar membresías a ",
};
