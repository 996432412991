import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { TableSubscriberAdmin } from "../Subscriber";
import "./SearchBar.scss";

export function SearchBar(props) {
  const {
    subscribers,
    updateSubscriber,
    deleteSubscriber,
    addMembershipSubscriber,
  } = props;
  const [query, setQuery] = useState("");
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    setDataFilter(filterTable(subscribers, query));
  }, [query, subscribers]);

  const handleChange = (word) => {
    setQuery(word.target.value);
  };

  return (
    <>
      <div className="searchbar-container">
        <div className="searchbar-wrapper">
          <Input
            size="large"
            icon="search"
            placeholder="Search..."
            onChange={handleChange}
          />
        </div>
        <div className="counter-wrapper">
          <SubscriberCounter subscribers={subscribers} />
        </div>
      </div>
      <TableSubscriberAdmin
        subscribers={dataFilter.reverse()}
        updateSubscriber={updateSubscriber}
        deleteSubscriber={deleteSubscriber}
        addMembershipSubscriber={addMembershipSubscriber}
      />
    </>
  );
}

function filterTable(data, search) {
  return data.filter((item) => {
    if (
      item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      item.identification.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      item.phone_number.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      return item;
    }
    return false;
  });
}

function SubscriberCounter(props) {
  const { subscribers } = props;
  return (
    <div className="cuadro-contador">
      <h3>{`Tienes un total de ${subscribers.length} personas registradas`}</h3>
    </div>
  );
}
