import React, {useEffect, useState} from "react";
import {Button, Form, Label} from "semantic-ui-react";
import {useFormik} from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";
import {useMembership, useMembershipSubscriber} from "../../../hooks";
import {addRelativeDate, isDateTypeEnumMONTH} from "../../../utils/membershipEnum";
import {da} from "date-fns/locale";
import {getDiscountTypeDISCOUNT, getDiscountTypeEnum} from "../../../utils";

export function AddMembershipSubscriber(props) {
    const { onClose, onRefetch, subscriber } = props;
    const { addMembershipSubscriber } = useMembershipSubscriber();

    var [selectedMembershipUse, setSelectedMembershipUse] = useState(null);

    const { getMembership, memberships } = useMembership();

    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        getMembership();
    }, [refetch]);

    const handleSelectedMembershipChange = (selectedMembership) => {
        // Your logic here
        setSelectedMembershipUse(selectedMembership)
    };

    const formik = useFormik({
        initialValues: initialValues(subscriber),
        validationSchema: Yup.object(subscriber ? updateSchema() : newSchema()),
        validateOnChange: false,
        onSubmit: async (formValues) => {
            try {
                const { start_date, price, end_date, discount, additional_discount, quantity_memberships } = formValues;

                if (quantity_memberships <= 0) {
                    formik.setFieldError("quantity_memberships", "Quantity must be greater than 0");
                    return; // stop the submission
                }

                const total_price = getTotalPrice(price, discount, selectedMembershipUse, additional_discount, quantity_memberships)
                let newDiscount = price === total_price ? 0 : discount
                const subscriberData = {
                    start_date: moment(start_date).tz("America/Bogota").hour(3).format(),
                    price,
                    id_subscriber: subscriber.id,
                    end_date: moment(end_date)
                        .tz("America/Bogota")
                        .hour(1)
                        .minute(0)
                        .format(),
                    id_membership: selectedMembershipUse.id,
                    discount: newDiscount,
                    additional_discount: additional_discount,
                    quantity_memberships: quantity_memberships,
                    total_price: total_price,
                };

                await addMembershipSubscriber(subscriberData);

                onRefetch();
                onClose();
            } catch (error) {
                throw error;
            }
        },
    });
    const membershipOptions = memberships
        ? memberships.map((value) => ({
            key: value.id,
            text: value.name,
            value: value.name,
        }))
        : [];

    return (
        <Form className="add-edit-category-form" onSubmit={formik.handleSubmit}>
            <Form.Group widths="equal">
                <Form.Select
                    width={4}
                    fluid
                    options={membershipOptions}
                    placeholder="Membresía"
                    value={formik.values.time}
                    onChange={(e_, { value, options }) => {
                        const selectedOption = options.find(
                            (option) => option.value === value
                        );
                        const selectedMembership = memberships.find(
                            (membership) => membership.id === selectedOption?.key
                        );
                        handleSelectedMembershipChange(selectedMembership)
                        formik.setFieldValue("price", selectedMembership.price);
                        formik.setFieldValue("time", value);

                        const end_date = addRelativeDate(
                            formik.values.start_date,
                            selectedMembership.time,
                            formik.values.quantity_memberships
                        )

                        const formattedEndDate = getFormattedEndDate(end_date)
                        if (subscriber?.last_membership?.end_date){
                            formik.setFieldValue("end_date", formattedEndDate);
                        }
                        formik.setFieldValue("end_date", formattedEndDate);
                    }}
                />



                <Form.Input
                    width={4}
                    name="price"
                    placeholder="Precio"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.errors.price}
                    readOnly
                />

            </Form.Group>

            <Form.Group widths={"equal"}>

                <Form.Input
                    width={4}
                    type="number"
                    id="quantity_membership"
                    name="quantity_membership"
                    placeholder="Cantidad de membresías"
                    error={formik.errors.quantity_memberships}
                    value={formik.values.quantity_memberships}
                    min={0}
                    onChange={(e) => {
                        const quantity = e.target.value
                        formik.setFieldValue("quantity_memberships", quantity);
                        const end_date = addRelativeDate(
                            formik.values.start_date,
                            selectedMembershipUse.time,
                            quantity
                        )

                        const formattedEndDate = getFormattedEndDate(end_date)
                        formik.setFieldValue("end_date", formattedEndDate);

                    }}
                />
            </Form.Group>






            <Form.Input
                name="start_date"
                placeholder="Fecha de inicio"
                type="datetime-local"
                value={formik.values.start_date}
                onChange={(e_, { value, options }) => {
                    formik.setFieldValue("start_date", value);

                    if (selectedMembershipUse !=null){
                        const end_date = addRelativeDate(
                            value,
                            selectedMembershipUse.time,
                            formik.values.quantity_memberships
                        )

                        const formattedEndDate = getFormattedEndDate(end_date)

                        if (subscriber?.last_membership?.end_date){
                            formik.setFieldValue("end_date", formattedEndDate);
                        }
                        formik.setFieldValue("end_date", formattedEndDate);
                    }
                }
                }
                error={formik.errors.start_date}
            />

            <Form.Input
                name="end_date"
                placeholder="Fecha de finalización"
                type="datetime-local"
                value={formik.values.end_date}
                onChange={formik.handleChange}
                error={formik.errors.end_date}
                readOnly
            />


                { isDateTypeEnumMONTH(selectedMembershipUse?.time || "") && (
                    <div className="ui tag labels">
                        <a className="ui label">
                            -{getTotalDiscount(formik)} &emsp;  {getDiscountTypeEnum(subscriber.discount_type)} ({formik.values.discount})
                        </a>
                    </div>
                )

                }



            <br />

            {

            }
            <Form.Input
                name="additional_discount"
                placeholder="Descuento adicional"
                value={formik.values.additional_discount}
                onChange={formik.handleChange}
            />

            <Label horizontal>
                TOTAL <br /> ${getTotalPrice(
                    formik.values.price,
                formik.values.discount,
                selectedMembershipUse,
                formik.values.additional_discount,
                formik.values.quantity_memberships,
            )}
            </Label>

            <Button
                type="submit"
                primary
                fluid
                content={subscriber ? "Activar" : "Crear"}
            />
        </Form>
    );



}


const getTotalDiscount = (formik) => {
    const discount = formik.values.discount
    const quantity_memberships = formik.values.quantity_memberships

    if (quantity_memberships == 0) return 0

    return quantity_memberships * discount
}

const getTotalPrice = (price, discount, selectedMembershipUse, additional_discount, quantity_memberships) => {

    if (quantity_memberships == 0) return 0

    additional_discount = textToInt(additional_discount,0)
    if (isDateTypeEnumMONTH(selectedMembershipUse?.time || "")){
        const total = price * quantity_memberships - discount * quantity_memberships - additional_discount
        return total
    }else {
        return (price * quantity_memberships) - additional_discount
    }
}

function textToInt(text, defaultValue) {
    const result = parseInt(text, 10);
    return isNaN(result) ? defaultValue : result;
}


function getFormattedEndDate(end_date){
    const year = end_date.getFullYear();
    const month = String(end_date.getMonth() + 1).padStart(2, "0");
    const day = String(end_date.getDate()).padStart(2, "0");
    const hours = String("01").padStart(2, "0");
    const minutes = String(end_date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`
}

function getStartDate(endDate) {
    var utc = new Date()
    if (endDate == null){
        return formatDate(utc)
    }
    var lastDate = new Date(endDate);
    if (lastDate > utc) {
        return formatDate(lastDate);
    }
    return formatDate(utc);
}

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String("03").padStart(2, "0");
    const minutes = String("00").padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`
}

function initialValues(data) {
    return {
        start_date: getStartDate(data?.last_membership?.end_date),
        end_date: "",
        id_subscriber: data?.phone_number || "",
        id_membership: data?.birthdate || "",
        price: "",
        quantity_memberships: 1,
        additional_discount: "",
        image: "",
        discount: getDiscountTypeDISCOUNT(data.discount_type),
    };
}

function newSchema() {
    return {
        start_date: Yup.string().required("Fecha de inicio requerida"),
        end_date: Yup.string().required("Fecha de finalización requerida"),
        price: Yup.string().required("Se requiere precio para continuar"),
        quantity_memberships: Yup.string().required("Se requiere cantidad para continuar"),
    };
}

function updateSchema() {
    return {
        start_date: Yup.string().required("Fecha de inicio requerida"),
        end_date: Yup.string().required("Fecha de finalización requerida"),
        price: Yup.string().required("Se requiere precio para continuar"),
    };
}
