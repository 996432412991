import React, { useState } from "react";
import { Menu, Icon, Button } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";
import "./SideMenu.scss";
import { keywords } from "../../utils/keywords";

export function SideMenu(props) {
  const { children } = props;
  const { pathname } = useLocation();
  const { auth } = useAuth();
  const [menuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
    setMenuVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className={`side-menu-admin ${menuVisible ? "visible" : "hidden"}`}>
      <Button
        className={`menu-toggle ${menuVisible ? "hidden" : "visible"}`}
        icon
        onClick={toggleMenu}
        size="large"
        floated="right"
      >
        {menuVisible ? <Icon name="caret left" /> : <Icon name="caret right" />}
      </Button>
      <MenuLeft pathname={pathname} auth={auth} />
      <div className="content">{children}</div>
    </div>
  );
}

function MenuLeft(props) {
  const { pathname, auth } = props;

  return (
    <Menu fixed="left" borderless className="side" vertical>
      <Menu.Item as={Link} to={"/"} active={pathname === "/"}>
        <Icon name="user" /> {keywords.subscriber}
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={"/membership"}
        active={pathname === "/membership"}
      >
        <Icon name="address card outline" /> {keywords.membership}
      </Menu.Item>

      {auth.me?.is_staff && (
        <Menu.Item as={Link} to={"/users"} active={pathname === "/users"}>
          <Icon name="user secret" /> {"Vista oculta"}
        </Menu.Item>
      )}
    </Menu>
  );
}
