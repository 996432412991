// ExcelModal.js

import React, {useState} from "react";
import { Modal, Form, Button } from "semantic-ui-react";
import {statisticsApi} from "../../../api/statisticsApi";
import {BASE_URL} from "../../../utils";

export function ExcelModal({ isVisible, setIsVisible }) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleOk = async () => {
        // Handle sending request here
        try {
            const data = {
                start_date: startDate,
                end_date: endDate
            }
            const response = await statisticsApi.generate_excel(data);
            const url = await  response.file_url
            if (url) {
                const urlToOpen = await `${BASE_URL}${url}`
                window.open(urlToOpen, "_blank");
            }
        } catch (error) {


        }
        // Once done, you can close the modal
        setIsVisible(false);
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    return (
        <Modal open={isVisible} onClose={handleCancel}>
            <Modal.Header>Generar Excel</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        name="Fecha inicial"
                        placeholder="Fecha inicial"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <Form.Input
                        name="Fecha final"
                        placeholder="Fecha final"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button positive onClick={handleOk}>Submit</Button>
            </Modal.Actions>
        </Modal>
    );
}
