import React from "react";
import { Card } from "semantic-ui-react";

export function Cards({ title, description, onClick }) {
  return (
    <Card className="generic-card" onClick={onClick}>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
    </Card>
  );
}
