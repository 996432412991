import {getIdGYM} from "./utils/localStorageManager";
import {v4 as uuidv4} from "uuid";
import ClientToken from "./ClientToken";
import {BASE_API_GYM} from "../utils";

const url = `${BASE_API_GYM}/statistics/purchasing`;
class StatisticsApi {
    async generate_excel(data) {
        const ID_GYM = getIdGYM();
        try {
            const formData = new FormData();
            const formattedEndDate = data.end_date ? `${data.end_date}T23:59:00-05:00` : "";
            const formattedStartDate = data.start_date ? `${data.start_date}T00:00:01-05:00` : "";
            formData.append("start_date", formattedStartDate);
            formData.append("end_date", formattedEndDate);
            formData.append("id_gym", ID_GYM);


            const response = await ClientToken.post(url, formData);
            const result = await response.json();
            return result;
        } catch (error) {
            throw error;
        }
    }
}

export let statisticsApi = new StatisticsApi();