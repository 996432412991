import { BASE_URL } from "./constants";
export function getImageUrl(url) {
  if (url.includes("http")) {
    return url;
  }
  if (url.includes(":800")) {
    return url;
  }
  return BASE_URL + url;
}
