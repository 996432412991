import { BASE_API_GYM } from "../utils/constants";
import ClientToken from "./ClientToken";
import { getIdGYM } from "./utils/localStorageManager";
import {v4 as uuidv4} from "uuid";

const url = `${BASE_API_GYM}/membership_subscriber/`;
class MembershipSubscriberApi {
  async addMembership(data) {
    const ID_GYM = getIdGYM();
    try {
      const formData = new FormData();
      formData.append("start_date", data.start_date);
      formData.append("id", uuidv4());
      formData.append("end_date", data.end_date);
      formData.append("price", data.price);
      formData.append("is_active", true);
      formData.append("is_deleted", false);
      formData.append("discount", data.discount);
      formData.append("additional_discount", data.additional_discount);
      formData.append("quantity_memberships", data.quantity_memberships);
      formData.append("id_gym", ID_GYM);
      formData.append("total_price", data.total_price);
      formData.append("id_subscriber", data.id_subscriber);
      formData.append("id_membership", data.id_membership);
      const response = await ClientToken.post(url, formData);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export let membershipSubscriberApi = new MembershipSubscriberApi();
