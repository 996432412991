import { useState } from "react";
import { subscriberApi } from "../api/subscriberApi";

export function useSubscriber() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [subscribers, setSubscribers] = useState(null);

  const getSubscribers = async () => {
    try {
      setLoading(true);
      const responseApi = await subscriberApi.getSubscribers();
      const response = responseApi.filter((subscriber) => subscriber.is_deleted === false).sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)
      setLoading(false);
      setSubscribers(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addSubscriber = async (data) => {
    try {
      setLoading(true);
      await subscriberApi.addSubscriber(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateSubscriber = async (id, data) => {
    try {
      setLoading(true);
      await subscriberApi.updateSubscriber(id, data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteSubscriber = async (id) => {
    try {
      setLoading(true);
      await subscriberApi.deleteSubscriber(id);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  return {
    loading,
    error,
    subscribers,
    getSubscribers,
    deleteSubscriber,
    updateSubscriber,
    addSubscriber,
  };
}
