import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { map } from "lodash";
import "./TableMembership.scss";
import {getMembershipTimeEnumValue} from "../../../utils/membershipEnum";

export function TableMembership(props) {
  const { memberships, updateMembership, deleteMembership } = props;
  return (
    <Table className="table-category-admin">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Duración</Table.HeaderCell>
          <Table.HeaderCell>Precio</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {map(memberships, (element, index) => (
          <Table.Row key={index}>
            <Table.Cell>{element.name}</Table.Cell>
            <Table.Cell>{getMembershipTimeEnumValue(element.time)}</Table.Cell>
            <Table.Cell>{element.price}</Table.Cell>

            <Actions
              memberships={element}
              updatemembership={updateMembership}
              deletemembership={deleteMembership}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function Actions(props) {
  const { memberships, updatemembership, deletemembership } = props;

  return (
    <Table.Cell textAlign="right">
      <Button icon onClick={() => updatemembership(memberships)}>
        <Icon name="pencil" />
      </Button>
      <Button icon negative onClick={() => deletemembership(memberships)}>
        <Icon name="close" />
      </Button>
    </Table.Cell>
  );
}
