import config from "../config";



export const BASE_URL = process.env.NODE_ENV === 'production' ? config.production.apiUrl : config.development.apiUrl;

export const BASE_API = BASE_URL + "/api";
export const BASE_API_GYM = BASE_API + "/gym/admin";

export const TOKEN = "token";
