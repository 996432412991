import React from "react";
import { Table, Button, Image, Icon } from "semantic-ui-react";
import { map } from "lodash";
import "./TableSubscriberAdmin.scss";
import { da } from "date-fns/locale";
import { getImageUrl } from "../../../utils/imageUtils";

export function TableSubscriberAdmin(props) {
  const {
    subscribers,
    updateSubscriber,
    deleteSubscriber,
    addMembershipSubscriber,
  } = props;
  return (
    <Table className="table-category-admin">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Foto</Table.HeaderCell>
          <Table.HeaderCell>Identificación</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Telefono</Table.HeaderCell>
          <Table.HeaderCell>Edad</Table.HeaderCell>
          <Table.HeaderCell>Membresía</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {map(subscribers, (data, index) => (
          <Table.Row key={index}>
            <Table.Cell width={2}>
              {data.profile_image != null ? (
                <Image src={getImageUrl(data.profile_image)} />
              ) : null}
            </Table.Cell>
            <Table.Cell>{`${data.identification_type}: ${data.identification}`}</Table.Cell>
            <Table.Cell>{data.name.toUpperCase()}</Table.Cell>
            <Table.Cell>{data.phone_number}</Table.Cell>
            <Table.Cell>
              {CalculateAge(new Date(data.birthdate))} años
            </Table.Cell>
            <Table.Cell>
              <Buttom
                color={
                  GetColorBasedOnMemberships(data?.memberships)
                    .color
                }
                title={
                    GetColorBasedOnMemberships(data?.memberships)
                    .days +
                    "\n" +
                    GetColorBasedOnMemberships(data?.memberships)
                    .end_date
                }
              />
            </Table.Cell>

            <Actions
              data={data}
              updateSubscriber={updateSubscriber}
              deleteSubscriber={deleteSubscriber}
              addMembershipSubscriber={addMembershipSubscriber}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function Actions(props) {
  const { data, updateSubscriber, deleteSubscriber, addMembershipSubscriber } =
    props;

  return (
    <Table.Cell textAlign="right">
      <Button icon primary onClick={() => addMembershipSubscriber(data)}>
        <Icon name="plus" />
      </Button>
      <Button icon onClick={() => updateSubscriber(data)}>
        <Icon name="pencil" />
      </Button>
      {/*<Button icon negative onClick={() => deleteSubscriber(data)}>*/}
      {/*  <Icon name="close" />*/}
      {/*</Button>*/}
    </Table.Cell>
  );
}

function CalculateAge(date) {
  const edadDiferencia = Date.now() - date.getTime();
  const edadFecha = new Date(edadDiferencia);
  return Math.abs(edadFecha.getUTCFullYear() - 1970);
}

function getDaysRemainingMembership(membershipSubscriber) {
  if (membershipSubscriber.is_deleted) return 0;
  const now = new Date();
  const startDate = new Date(membershipSubscriber.start_date);
  const endDate = new Date(membershipSubscriber.end_date);

  if (now > endDate) {
    const difference = now.getTime() - endDate.getTime();
    let daysRemaining = Math.floor(difference / (1000 * 60 * 60 * 24));
    if (daysRemaining === 0 && difference / (1000 * 60 * 60) > 1) {
      daysRemaining = 1;
    }
    return daysRemaining * -1;
  }

  if (now > startDate && now < endDate) {

    const difference = endDate.getTime() - now.getTime();
    let daysRemaining = Math.floor(difference / (1000 * 60 * 60 * 24));
    if (daysRemaining === 0 && difference / (1000 * 60 * 60) > 1) {
      daysRemaining = 1;
    }
    return daysRemaining;
  }
  endDate.setHours(endDate.getHours() + 2)

  const difference = endDate.getTime() - startDate.getTime();
  const remaining = Math.floor(difference / (1000 * 60 * 60 * 24))

  return remaining;
}

function isBetweenDates(membershipSubscriber) {
  const now = new Date();
  const endDate = new Date(membershipSubscriber.end_date)
  const startDate = new Date(membershipSubscriber.start_date)
  return now > startDate && now < endDate;
}

function getActualMembership(membershipSubscriberList) {

  if (membershipSubscriberList == null) return null
  if (membershipSubscriberList.length === 0) return null

  const activeMembership = membershipSubscriberList.filter((membershipSubscriber) => isValidMembershipDate(membershipSubscriber)).find((membershipSubscriber) => isBetweenDates(membershipSubscriber));

  return activeMembership;
}
function getDaysRemaining(membershipSubscriberList) {
  try{
    if (membershipSubscriberList === null) return 0;
    if (membershipSubscriberList === undefined) return 0;
    if (membershipSubscriberList.length === 0) return 0;


    const activeMembershipList = membershipSubscriberList.filter((element) =>
        isValidMembershipDate(element)
    );

    var days = 0;
    activeMembershipList.forEach((element) => {
      days += getDaysRemainingMembership(element);
    });
    if (days === 0) {
      if (membershipSubscriberList.length === 0){

      }else {
        let ordered = membershipSubscriberList.sort((a,b) => a.end_date - b.end_date);
        return (getDaysRemainingMembership(ordered[0]))
      }

    }
    return days;
  }catch (e) {
    return 0;
  }
}

function isValidMembershipDate(membershipSubscriber) {

  if (membershipSubscriber.is_active === false && membershipSubscriber.is_deleted) return false
  const now = new Date();
  const endDate = new Date(membershipSubscriber.end_date)
  return (now <= endDate);
}

function GetColorBasedOnDaysDifference(diffInDays, endDate){
  var color = "GRAY";
  let dayStr = "días";
  if (diffInDays > 7) {
    color = "GREEN";
  } else if (diffInDays > 2) {
    color = "GOLD";
  } else if (diffInDays > 0) {
    if (diffInDays === 1) {
      dayStr = "día";
    }
    color = "RED";
  } else {
    color = "GRAY";
  }

  let formatedEndDate = formatISODateTime(endDate)
  return {
    color: color,
    days: diffInDays + " " + dayStr,
    end_date: formatedEndDate.formattedDate + " " + formatedEndDate.formattedTime ,
  };
}

function formatISODateTime(isoDateTime) {

  let formattedDate = ``;
  let formattedTime = ``;
  if (isoDateTime === "" || isoDateTime === undefined) return {
    formattedDate,
    formattedTime
  };


  const date = new Date(isoDateTime);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  formattedDate = `${year}-${month}-${day}`;
  formattedTime = `${hours}:${minutes}:${seconds}`;

  return {
    formattedDate,
    formattedTime
  };
}


function GetColorBasedOnMemberships(membershipSubscriberList) {
  
  let remainingDays = getDaysRemaining(membershipSubscriberList)
  let actualMembership = getActualMembership(membershipSubscriberList)?.end_date ?? getLastMembershipEndDate(membershipSubscriberList)
  return GetColorBasedOnDaysDifference(remainingDays,actualMembership )
}

function getLastMembershipEndDate(membershipSubscriberList){

  try {
    const activeMembershipList = membershipSubscriberList.filter((element) =>
        isValidMembershipDate(element)
    );
    if (activeMembershipList.length === 0){
      return ""
    }
    return activeMembershipList.sort((a,b) => a.end_date - b.end_date)[0].end_date
  }catch (e) {
    return ""
  }

}
function GetColorBasedOnDateDifference(endDateMembership) {
  var color = "GRAY";
  if (endDateMembership == null) {
    return {
      color: color,
      days: "No tiene membresia",
      end_date: "",
    };
  }
  const endDate = new Date(endDateMembership);
  const todayDate = new Date();
  todayDate.setHours(3, 0, 0, 0);
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  const diffInDays = Math.round((endDate - todayDate) / oneDay);
  return GetColorBasedOnDaysDifference(diffInDays, endDate)
}

function Buttom(props) {
  const { color, title } = props;
  const buttonStyle = {
    backgroundColor: color,
    fontSize: "1rem",
    color: "white",
    width: "100%",
    whiteSpace: "pre-line",
  };

  return (
    <button className="ui button label" style={buttonStyle}>
      {title}
    </button>
  );
}
