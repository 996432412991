import {BASE_API_GYM} from "../utils/constants";
import ClientToken from "./ClientToken";
import {getIdGYM} from "./utils/localStorageManager";
import {v4 as uuidv4} from 'uuid';

const url = `${BASE_API_GYM}/subscriber/`;

class SubscriberApi {
  async addSubscriber(data) {
    const ID_GYM = getIdGYM();
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("id", `${ID_GYM}-${data.identification}`);
      if (data.profile_image != null) {
        formData.append("profile_image", data.profile_image);
      }
      formData.append("nickname", data.nickname);
      formData.append("identification_type", data.identification_type);
      formData.append("identification", data.identification);
      formData.append("phone_number", data.phone_number);
      formData.append("birthdate", data.birthdate);
      formData.append("profile_image", data.image);
      formData.append("id_gym", ID_GYM);
      formData.append("qr_code", uuidv4());
      formData.append("is_active", true);
      formData.append("is_deleted", false);
      formData.append("discount_type", data.discount_type);
      if (data.discount_associated_id != null) {
        formData.append("discount_familiar_associated_id", data.discount_associated_id);
        formData.append("discount_familiar_kinship", data.discount_familiar_kinship);
      }


      const response = await ClientToken.post(url, formData);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateSubscriber(id, data) {
    const ID_GYM = getIdGYM();
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.profile_image != null) {
        formData.append("profile_image", data.profile_image);
      }
      formData.append("nickname", data.nickname);
      formData.append("identification_type", data.identification_type);
      formData.append("identification", data.identification);
      formData.append("phone_number", data.phone_number);
      formData.append("discount_type", data.discount_type);
      if (data.discount_associated_id != null) {
        formData.append("discount_familiar_associated_id", data.discount_associated_id);
        formData.append("discount_familiar_kinship", data.discount_familiar_kinship);
      }

      formData.append(
        "birthdate",
        new Date(data.birthdate).toISOString().split("T")[0]
      );
      formData.append("profile_image", data.image);
      formData.append("id_gym", ID_GYM);

      const newUrl = `${url}${id}/`;

      const response = await ClientToken.patch(newUrl, formData);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteSubscriber(id) {
    try {
      const newUrl = `${url}${id}/`;
      const response = await ClientToken.delete(newUrl);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getSubscriberById(id) {
    try {
      const response = await ClientToken.getWithId(id);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getSubscribers() {
    const ID_GYM = getIdGYM();
    const urlWithID = `${url}?id_gym=${ID_GYM}`;
    try {
      const response = await ClientToken.get(urlWithID);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export let subscriberApi = new SubscriberApi();
