import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSubscriber } from "../../../hooks";
import {
  getDiscountTypeEnum,
  identificationTypeArray,
  relationshipTypeArray,
} from "../../../utils";
import "./AddEditSubscriberForm.scss";
import {
  discountTypeEnumArray,
  getKeyDiscountTypeEnumByValue,
  isDiscountTypeEnumFamiliar,
} from "../../../utils/discountEnum";
import { da } from "date-fns/locale";
import { getImageUrl } from "../../../utils/imageUtils";

export function AddEditSubscriberForm(props) {
  const { onClose, onRefetch, subscriber } = props;
  const [previewImage, setPreviewImage] = useState(
    subscriber ? subscriber?.image : null
  );
  const { addSubscriber, updateSubscriber } = useSubscriber();

  function initialValues(data) {
    let discountType = "";
    let nameSelected = "";
    if (data !== undefined) {
      discountType = getDiscountTypeEnum(data.discount_type) || "";
      const selectedMemberid = subscriber.discount_associated_id;

      if (subscriber.discount_associated_id != null && subscribers != null) {
        const selectedSubscriber = subscribers.find(
          (option) => option.id === selectedMemberid
        );

        if (selectedMemberDiscountUse == null) {
          setSelectedMemberDiscountUse(selectedSubscriber);
        }
        nameSelected = selectedSubscriber.name;
      }
    }

    return {
      name: data?.name.toUpperCase() || "",
      nickname: data?.nickname || "",
      phone_number: data?.phone_number || "",
      birthdate: data?.birthdate || "",
      identification_type: data?.identification_type || "CC",
      identification: data?.identification || "",
      image: data?.profile_image || "",
      discount_type: discountType,
      discount_familiar_kinship: data?.discount_familiar_kinship || "",
      relationship_user: nameSelected,
    };
  }

  const [refetch, setRefetch] = useState(false);
  const [haveFamiliarDiscount, setHaveFamiliarDiscount] = useState(false);
  var [selectedMemberDiscountUse, setSelectedMemberDiscountUse] =
    useState(null);

  const { getSubscribers, subscribers } = useSubscriber();

  useEffect(() => {
    getSubscribers();
  }, [refetch]);

  const formik = useFormik({
    initialValues: initialValues(subscriber),
    validationSchema: Yup.object(subscriber ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        const newValues = formValues;
        newValues.discount_type = getKeyDiscountTypeEnumByValue(
          newValues.discount_type
        );

        if (selectedMemberDiscountUse != null) {
          newValues.discount_associated_id = selectedMemberDiscountUse.id;
        }
        newValues.relationship_user = null;
        if (newValues.discount_familiar_kinship.isEmpty) {
          newValues.discount_familiar_kinship = null;
        }
        if (subscriber) {
          await updateSubscriber(subscriber.id, newValues);
        } else {
          await addSubscriber(newValues);
        }

        onRefetch();
        onClose();
      } catch (error) {
        throw error;
      }
    },
  });

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("image", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  return (
    <Form className="add-edit-category-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="name"
        placeholder="Nombre"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && formik.errors.name}
      />

      <Form.Group widths="equal">
        <Form.Select
          width={2}
          fluid
          options={identificationTypeArray.map((value) => ({
            key: value,
            text: value,
            value: value,
          }))}
          placeholder="Tipo"
          onChange={(e, { value }) =>
            formik.setFieldValue("identification_type", value)
          }
          value={formik.values.identification_type}
          error={
            formik.touched.identification_type &&
            formik.errors.identification_type
          }
        />
        <Form.Input
          width={8}
          name="identification"
          placeholder="Número de identificación"
          value={formik.values.identification}
          onChange={formik.handleChange}
          error={formik.touched.identification && formik.errors.identification}
        />
      </Form.Group>

      {/*<Form.Input
        name="nickname"
        placeholder="Nickname"
        value={formik.values.nickname}
        onChange={formik.handleChange}
        error={formik.touched.nickname && formik.errors.nickname}
      />*/}

      <Form.Input
        name="phone_number"
        placeholder="Teléfono"
        value={formik.values.phone_number}
        onChange={formik.handleChange}
        error={formik.touched.phone_number && formik.errors.phone_number}
      />

      <Form.Input
        name="birthdate"
        placeholder="Fecha de nacimiento"
        type="date"
        value={formik.values.birthdate}
        onChange={formik.handleChange}
        error={formik.touched.birthdate && formik.errors.birthdate}
      />

      <Form.Group widths="equal">
        <Form.Select
          width={4}
          fluid
          options={discountTypeEnumArray.map((value) => ({
            key: getKeyDiscountTypeEnumByValue(value),
            text: value,
            value: value,
          }))}
          placeholder="Descuento"
          onChange={(e, { value, options }) => {
            const selectedOption = options.find(
              (option) => option.value === value
            );
            const selectedKey = selectedOption.key;
            setHaveFamiliarDiscount(isDiscountTypeEnumFamiliar(selectedKey));

            if (isDiscountTypeEnumFamiliar(selectedKey)) {
              formik.setFieldValue("discount_familiar_kinship", "");
              formik.setFieldValue("relationship_user", "");
              setSelectedMemberDiscountUse(null);
            }

            formik.setFieldValue("discount_type", value);
          }}
          value={formik.values.discount_type}
        />
        {/*{haveFamiliarDiscount && (*/}
        {isDiscountTypeEnumFamiliar(
          getKeyDiscountTypeEnumByValue(formik.values.discount_type)
        ) && (
          <Form.Field
            width={6}
            search
            selection
            options={relationshipTypeArray.map((value) => ({
              key: value,
              text: value,
              value: value,
            }))}
            name="Parentesco"
            control={Dropdown}
            placeholder="Parentesco"
            value={formik.values.discount_familiar_kinship}
            onChange={(e, { value }) => {
              formik.setFieldValue("discount_familiar_kinship", value);
            }}
            error={formik.errors.identification}
          />
        )}
      </Form.Group>

      {isDiscountTypeEnumFamiliar(
        getKeyDiscountTypeEnumByValue(formik.values.discount_type)
      ) && (
        <Form.Group widths="equal">
          <Form.Field
            width={7}
            fluid
            search
            selection
            options={
              subscribers
                ? subscribers.map((value) => ({
                    key: value.name,
                    text: value.name,
                    value: value.name,
                  }))
                : []
            }
            name="Usuario"
            control={Dropdown}
            placeholder="Usuario"
            value={formik.values.relationship_user}
            onChange={(e, { value }) => {
              // Retrieve the selected membership based on the value
              const selectedSubscriber = subscribers.find(
                (subscriber) => subscriber.name === value
              );
              // Do something with the selected membership
              setSelectedMemberDiscountUse(selectedSubscriber);

              formik.setFieldValue("relationship_user", value);
            }}
            error={formik.errors.identification}
          />
        </Form.Group>
      )}

      <Button
        type="button"
        fluid
        {...getRootProps()}
        color={formik.errors.image && "red"}
      >
        {previewImage ? "Cambiar imagen" : "Subir imagen"}
      </Button>
      <input {...getInputProps()} />
      <Image
        src={previewImage ? previewImage : getImageUrl(formik.values.image)}
      />

      <Button
        type="submit"
        primary
        fluid
        content={subscriber ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function newSchema() {
  return {
    name: Yup.string().required("El nombre es requerido"),
    identification_type: Yup.string().required(
      "Seleccione tipo de identificación"
    ),
    identification: Yup.string().required(
      "El número de documento es requerido"
    ),
    // nickname: Yup.string().required("El nickname es requerido"),
    phone_number: Yup.string().required("El número de teléfono es requerido"),
    birthdate: Yup.date().required("La fecha de nacimiento es requerida"),
    image: Yup.string(),
  };
}

function updateSchema() {
  return {
    name: Yup.string().required("El nombre es requerido"),
    identification_type: Yup.string().required(
      "Seleccione tipo de identificación"
    ),
    identification: Yup.string().required(
      "El número de documento es requerido"
    ),
    // nickname: Yup.string().required("El nickname es requerido"),
    phone_number: Yup.string().required("El número de teléfono es requerido"),
    birthdate: Yup.date().required("La fecha de nacimiento es requerida"),
    image: Yup.string(),
  };
}
