const relationshipTypeEnum = {
  PADRE: "PADRE",
  MADRE: "MADRE",
  HERMANO: "HERMANO",
  HERMANA: "HERMANA",
  HIJO: "HIJO",
  HIJA: "HIJA",
  ABUELO: "ABUELO",
  ABUELA: "ABUELA",
  NIETO: "NIETO",
  NIETA: "NIETA",
  TIO: "TIO",
  TIA: "TIA",
  SOBRINO: "SOBRINO",
  SOBRINA: "SOBRINA",
  PRIMO: "PRIMO",
  PRIMA: "PRIMA",
};

export const relationshipTypeArray = Object.values(relationshipTypeEnum);
