import { BASE_API_GYM } from "../utils/constants";
import ClientToken from "./ClientToken";

const url = `${BASE_API_GYM}/gym_access/`;
class AsccessGymApi {
  async getAsccessGym() {
    try {
      const response = await ClientToken.get(url);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export let asccessGymApi = new AsccessGymApi();
