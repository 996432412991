/* eslint-disable import/no-anonymous-default-export */
import { getToken } from "./token";

const token = getToken();

export default {
  async getWithId(url, id) {
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(`${url}/${id}`, params);
  },
  async get(url, data) {
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    return await fetch(url, params);
  },
  async post(url, data) {
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };
    return await fetch(url, params);
  },
  async patch(url, data) {
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };
    return await fetch(url, params);
  },
  async delete(url) {
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(url, params);
  },
};
