const DiscountTypeEnum = {
  FAMILY: "FAMILIA",
  ARMED_FORCES: "FUERZAS ARMADAS",
  EDUCATION: "ESTUDIANTE",
  NONE: "NINGUNO",
};

export function getDiscountTypeEnum(key) {
  return DiscountTypeEnum[key] || "";
}

export function getDiscountTypeDISCOUNT(key) {
  const newKey = (getDiscountTypeEnum(key))
  switch (newKey){
    case DiscountTypeEnum.ARMED_FORCES:
      return 10000
    case DiscountTypeEnum.FAMILY:
      return 10000
    case DiscountTypeEnum.EDUCATION:
      return 10000
    case DiscountTypeEnum.NONE:
      return 0
    default:
      return 0.0
  }
}

export const discountTypeEnumArray = Object.values(DiscountTypeEnum);

function getKeyByValue(enumObj, value) {
  for (const key in DiscountTypeEnum) {
    if (DiscountTypeEnum[key] === value) {
      return key;
    }
  }
  return null;
}

export const getKeyDiscountTypeEnumByValue = (data) => getKeyByValue(DiscountTypeEnum, data);

export function isDiscountTypeEnumFamiliar(key){
  return key === getKeyDiscountTypeEnumByValue(DiscountTypeEnum.FAMILY);
}