import { useState } from "react";
import { membershipSubscriberApi } from "../api/membershipSubscriberApi";
import { getKeyDateTypeEnum } from "../utils/membershipEnum";

export function useMembershipSubscriber() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [membershipSubscriber, setMembershipSubscriber] = useState(null);

  const getMembershipSubscriber = async () => {
    try {
      setLoading(true);
      const response = await membershipSubscriberApi.getMemberships();
      setLoading(false);
      setMembershipSubscriber(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addMembershipSubscriber = async (data) => {
    try {
      setLoading(true);
      data.time = getKeyDateTypeEnum(data.time);
      await membershipSubscriberApi.addMembership(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    membershipSubscriber,
    getMembershipSubscriber,
    addMembershipSubscriber,
  };
}
