import React from "react";
import { Form, Button } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMembership } from "../../../hooks";
import "./AddEditMembershipForm.scss";
import {
  DateTypeArray,
  getMembershipTimeEnumValue,
  getKeyDateTypeEnum,
} from "../../../utils/membershipEnum";

export function AddEditMembershipForm(props) {
  const { onClose, onRefetch, memberships } = props;
  const { addMembership, updateMembership } = useMembership();

  const formik = useFormik({
    initialValues: initialValues(memberships),
    validationSchema: Yup.object(memberships ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        const { name, price, time } = formValues;
        const membershipData = {
          name,
          price,
          time,
        };

        if (memberships) {
          await updateMembership(memberships.id, membershipData);
        } else {
          await addMembership(membershipData);
        }

        onRefetch();
        onClose();
      } catch (error) {
        throw error;
      }
    },
  });

  return (
    <Form className="add-edit-category-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="name"
        placeholder="Nombre"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.errors.name}
      />

      <Form.Group widths="equal">
        <Form.Select
          width={2}
          fluid
          options={DateTypeArray.map((value) => ({
            key: value,
            text: value,
            value: value,
          }))}
          placeholder="Tipo"
          value={formik.values.time}
          onChange={(e_, { value }) => formik.setFieldValue("time", value)}
        />
      </Form.Group>
      <Form.Input
        name="price"
        placeholder="Precio de la membresía"
        value={formik.values.price}
        onChange={formik.handleChange}
        error={formik.errors.price}
      />

      <Button
        type="submit"
        primary
        fluid
        content={memberships ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function initialValues(data) {
  return {
    name: data?.name || "",
    price: data?.price || "",
    time: getMembershipTimeEnumValue(data?.time) || getMembershipTimeEnumValue("DAY"),
  };
}

function newSchema() {
  return {
    name: Yup.string().required("El nombre es requerido"),
    price: Yup.string().required("El precio es requerido"),
  };
}

function updateSchema() {
  return {
    name: Yup.string().required("El nombre es requerido"),
    price: Yup.string().required("El precio es requerido"),
  };
}
