import { useState } from "react";
import { asccessGymApi } from "../api/asccessGymApi";

export function useAsccessGym() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [asccessGym, setAsccessGym] = useState(null);

  const getAsccessGym = async () => {
    try {
      setLoading(true);
      const response = await asccessGymApi.getAsccessGym();
      setLoading(false);
      setAsccessGym(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  return {
    loading,
    error,
    asccessGym,
    getAsccessGym,
  };
}
