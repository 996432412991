import React, { useEffect } from "react";
import { useAuth, useAsccessGym } from "../../hooks";
import { Cards } from "../../components/Cards/Card";

export function SelectGYM() {
  const { login } = useAuth();
  const { asccessGym, getAsccessGym } = useAsccessGym();

  useEffect(() => {
    getAsccessGym();
  }, []);

  const handleCardClick = (gym) => {
    // Lógica para manejar el clic en la tarjeta
    const token = localStorage.getItem("token");
    const idGYM = gym.id
    localStorage.setItem("ID_GYM", idGYM);
    localStorage.setItem("GYM_NAME", gym.name);
    login(token, idGYM);
  };

  return (
    <div className="view-container">
      <h1>Selecciona un elemento:</h1>
      {asccessGym?.map((element, index) => (
        <Cards
          key={index}
          title={element.name}
          description={"Aqui va una imagen"}
          onClick={() => handleCardClick(element)}
        />
      ))}
    </div>
  );
}
