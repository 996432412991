import React from "react";
import { LoginFrom } from "../../../components";
import "./Login.scss";

export function Login() {
  return (
    <div className="login">
      <div className="login__content">
        <h1>Entrar al panel</h1>
        <LoginFrom />
      </div>
    </div>
  );
}
