import { BASE_API_GYM } from "../utils/constants";
import ClientToken from "./ClientToken";
import { getIdGYM } from "./utils/localStorageManager";

const url = `${BASE_API_GYM}/membership/`;
class MembershipApi {
  async addMembership(data) {
    const ID_GYM = getIdGYM();
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("price", data.price);
      formData.append("time", data.time);
      formData.append("id_gym", ID_GYM);
      formData.append("is_active", true);
      formData.append("is_deleted", false);
      const response = await ClientToken.post(url, formData);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateMembership(id, data) {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("price", data.price);
      formData.append("time", data.time);

      const newUrl = `${url}${id}/`;

      const response = await ClientToken.patch(newUrl, formData);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteMembership(id) {
    try {
      const newUrl = `${url}${id}/`;
      const response = await ClientToken.delete(newUrl);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getMembershipById(id) {
    try {
      const response = await ClientToken.getWithId(id);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getMemberships() {
    const ID_GYM = getIdGYM();
    try {
      const urlWithID = `${url}?id_gym=${ID_GYM}`;
      const response = await ClientToken.get(urlWithID);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export let membershipApi = new MembershipApi();
