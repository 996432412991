import { getMeApi } from "../api";

export function useUser() {
  const getMe = async () => {
    try {
      const response = await getMeApi();
      return response;
    } catch (error) {
      throw error;
    }
  };

  return { getMe };
}
