import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import {
  ModalBasic,
  HeaderPage,
  AddEditSubscriberForm,
  SearchBar,
  AddMembershipSubscriber,
} from "../../components";
import { useSubscriber } from "../../hooks";
import { keywords } from "../../utils/keywords";

export function Home() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading, subscribers, getSubscribers, deleteSubscriber } =
    useSubscriber();

  useEffect(() => {
    getSubscribers();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addSubscriber = () => {
    setTitleModal(
      `${keywords.new} ${keywords.subscriber.toLowerCase().slice(0, -1)}`
    );
    setContentModal(
      <AddEditSubscriberForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateSubscriber = (subscriber) => {
    setTitleModal(keywords.update);
    setContentModal(
      <AddEditSubscriberForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        subscriber={subscriber}
      />
    );
    openCloseModal();
  };
  const addMembershipSubscriber = (data) => {

    setTitleModal(keywords.addMembershipSubscriber + `${data.name}`);
    setContentModal(
      <AddMembershipSubscriber
        onClose={openCloseModal}
        onRefetch={onRefetch}
        subscriber={data}
      />
    );
    openCloseModal();
  };

  const onDeleteSubscriber = async (subscriber) => {
    const result = window.confirm(
      `¿${keywords.delete} ${keywords.subscriber.toLowerCase().slice(0, -1)} ${
        subscriber.name
      }?`
    );
    if (result) {
      await deleteSubscriber(subscriber.id);
      onRefetch();
    }
  };

  return (
    <>
      <HeaderPage
        title={keywords.subscriber}
        btnTitle={`${keywords.new} ${keywords.subscriber
          .toLowerCase()
          .slice(0, -1)}`}
        btnClick={addSubscriber}
      />
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <SearchBar
          subscribers={subscribers}
          updateSubscriber={updateSubscriber}
          deleteSubscriber={onDeleteSubscriber}
          addMembershipSubscriber={addMembershipSubscriber}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
