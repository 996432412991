import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { keywords } from "../../utils/keywords";
import { loginApi } from "../../api";
import "./LoginFrom.scss";
import { SelectGYM } from "../../pages"; // Asegúrate de importar correctamente el componente
import { setToken } from "../../api/token";

export function LoginFrom() {
  const [tokenState, setTokenState] = useState(null);

  useEffect(() => {
    if (tokenState !== null) {
      const token = localStorage.getItem("token");
      if (token !== null) {
        setTokenState(token);
      }
    }
  }, []);

  const formik = useFormik({
    validationSchema: Yup.object(validationSchema()),
    initialValues: initialValues(),
    onSubmit: async (formValue) => {
      try {
        const response = await loginApi(formValue);
        const { access } = response;
        if (access != undefined) {
          await setToken(access);
        }
        //Navigate("/select-gym");
        setTokenState(access);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  if (tokenState !== null) {
    return <SelectGYM />;
  }

  return (
    <Form className="login-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="email"
        placeholder={keywords.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.errors.email}
      />
      <Form.Input
        name="password"
        placeholder={keywords.password}
        type="password"
        values={formik.values.password}
        onChange={formik.handleChange}
        error={formik.errors.password}
      />
      <Button type="submit" content={keywords.login} primary fluid />
    </Form>
  );
}

function initialValues() {
  return { email: "", password: "", access: "" };
}

function validationSchema() {
  return {
    email: Yup.string().email(keywords.emailError).required(true),
    password: Yup.string().required(keywords.passwordError),
  };
}
