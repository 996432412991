import React, { createContext, useState, useEffect } from "react";
import { getToken, setToken, removeToken } from "../api/token";
import { useUser } from "../hooks";
import { getIdGYM } from "../api/utils/localStorageManager";

export const AuthContext = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});

export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState(undefined);
  const { getMe } = useUser();

  useEffect(() => {
    (async () => {
      const token = getToken();
      if (token) {
        const me = await getMe();
        const gymId = await getIdGYM();
        if (gymId) {
          setAuth({ token, me });
        } else {
          setAuth(null);
        }
      } else {
        setAuth(null);
      }
    })();
  }, []);

  const login = async (token, idGYM) => {
    const me = await getMe();
    const gymId = idGYM; // Obtén el ID del gimnasio desde la respuesta del servidor
    if (gymId) {
      setToken(token);
      window.location.reload();
      setAuth({ token, me });
    } else {
      // Mostrar mensaje de error o redirigir a una página de error
    }
  };

  const logout = () => {
    if (auth) {
      removeToken();
      setAuth(null);
    }
  };

  const authContextValue = {
    auth: auth,
    login: login,
    logout: logout,
  };

  if (auth === undefined) return null;

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}
