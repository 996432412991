import React, { useState, useEffect } from "react";
import {
  ModalBasic,
  HeaderPage,
  AddEditMembershipForm,
  TableMembership,
} from "../../components";
import { useMembership } from "../../hooks";
import { keywords } from "../../utils/keywords";
import { Loader } from "semantic-ui-react";
export function Membership() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading, getMembership, memberships, deleteMembership } =
    useMembership();

  useEffect(() => {
    getMembership();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addMembership = () => {
    setTitleModal(
      `${keywords.new} ${keywords.membership.toLowerCase().slice(0, -1)}`
    );
    setContentModal(
      <AddEditMembershipForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateMembership = (data) => {
    setTitleModal(keywords.update);
    setContentModal(
      <AddEditMembershipForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        memberships={data}
      />
    );
    openCloseModal();
  };

  const onDeleteMembership = async (data) => {
    const result = window.confirm(`¿${keywords.deleteMesage} ${data.name}?`);
    if (result) {
      await deleteMembership(data.id);
      onRefetch();
    }
  };

  return (
    <>
      <HeaderPage
        title={keywords.membership}
        btnTitle={`${keywords.new} ${keywords.membership
          .toLowerCase()
          .slice(0, -1)}`}
        btnClick={addMembership}
      />
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <TableMembership
          memberships={memberships}
          updateMembership={updateMembership}
          deleteMembership={onDeleteMembership}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
