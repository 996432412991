import { AdminLayout } from "../layouts";
import {
  Error404,
  Home,
  Users,
  Membership,
  AddMembership,
  SelectGYM,
} from "../pages";

const routesAdmin = [
  {
    path: "/",
    layout: AdminLayout,
    component: Home,
    exact: true,
  },
  {
    path: "/users",
    layout: AdminLayout,
    component: Users,
    exact: true,
  },
  {
    path: "/*",
    layout: AdminLayout,
    component: Error404,
    exact: true,
  },
  {
    path: "/membership",
    layout: AdminLayout,
    component: Membership,
    exact: true,
  },
  {
    path: "/add-membership",
    layout: AdminLayout,
    component: AddMembership,
    exact: true,
  },
  {
    path: "/select-gym",
    layout: AdminLayout,
    component: SelectGYM,
    exact: true,
  },
];

export default routesAdmin;
